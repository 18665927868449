.container {
  position: relative;
  height: 22px;
  min-width: 22px;
  display: flex;
  align-items: center;
}

.checkboxContainer input {
  position: absolute;
  opacity: 0;
  left: 0;
  top: 0;
}

.checkmark {
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  border-radius: 3px;
  background-color: #ffffff;
  border: solid 2px #dde6ec;
}

.checkboxText {
  cursor: pointer;
  font-family: Muli;
  font-size: 13px;
  font-weight: bold;
  text-align: left;
  color: #354052;
  margin-left: 28px;
  user-select: none;
}

.checkboxContainer:hover input ~ .checkmark {
  border-color: #c3cbd1;
}

.checkboxContainer input:checked ~ .checkmark {
  background-color: #47a9f2;
  border-color: #47a9f2;
}

.checkboxContainer input:checked:hover ~ .checkmark {
  background-color: #2594e7;
  border-color: #2594e7;
}

.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

.checkboxContainer input:checked ~ .checkmark:after {
  display: block;
}

.checkboxContainer .checkmark:after {
  left: 6px;
  top: 3px;
  width: 4px;
  height: 7px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.optionalCheckboxContainer .checkmark:after {
  left: 4px;
  top: 8px;
  width: 10px;
  height: 2px;
  border-radius: 2px;
  border-width: 0px;
  background-color: #ffffff;
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
}
