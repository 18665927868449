:root {
  --decline-bd: #f57333;
  --decline-1-bg: #ff7f00;
  --decline-2-bg: #fc9630;
  --approve-bd: #1a91eb;
  --approve-1-bg: #2594e7;
  --approve-2-bg: #47a9f2;
}

.cnt {
  padding: 4px;
  border-radius: 3px;
  background-color: #f4f7f9;
  border: solid 2px #dde6ec;
}

.slcCnt {
  display: flex;
  flex-wrap: wrap;
}

.el {
  padding: 5px 32px 5px 10px;
  border-radius: 3px;
  font-family: 'Muli';
  font-size: 11px;
  font-weight: bold;
  color: #ffffff;
  position: relative;
  margin: 4px;
}

.close {
  width: 14px;
  height: 14px;
  position: absolute;
  top: 5px;
  right: 5px;
  opacity: 0.7;
  content: '';
}
.close:hover {
  opacity: 1;
  cursor: pointer;
}
.close:before,
.close:after {
  position: absolute;
  left: 6px;
  top: 2px;
  content: ' ';
  height: 10px;
  width: 2px;
  background-color: #fff;
}
.close:before {
  transform: rotate(45deg);
}
.close:after {
  transform: rotate(-45deg);
}

.el.approve {
  border: 1px solid var(--approve-bd);
}
.el.approve.fst {
  background-color: var(--approve-1-bg);
}
.el.approve.scd {
  background-color: var(--approve-2-bg);
}

.el.decline {
  border: 1px solid var(--decline-bd);
}

.el.decline.fst {
  background: var(--decline-1-bg);
}

.el.decline.scd {
  background: var(--decline-2-bg);
}

.inpt {
  border: none;
  background: none;
}

.inpt:focus {
  outline: none;
}
