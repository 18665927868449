@import (reference) '~src/theme/colors.less';

.AlertItemPreview {
  min-height: 66px;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;

  &__default {
    border-radius: 3px 3px 0 0;
    background-color: #dde6ec;
    cursor: pointer;
  }

  &__moderated {
    border-radius: 3px 3px 3px 3px;
    background-color: #ffffff;
  }

  .createCategorySpecificClass(@categoryName, @color) {
    @color10: '@{color}10';
    @color65: '@{color}65';

    &__@{categoryName} {
      background-color: @@color10;
      border: 1px solid @@color10;
      transition: border-color 0.3s;

      &:hover {
        border-color: @@color65;
      }
    }
  }

  .createCategorySpecificClass(crime, red);
  .createCategorySpecificClass(solicitor, yellow);
  .createCategorySpecificClass(suspiciousActivity, purple);
  .createCategorySpecificClass(safety, green);
  .createCategorySpecificClass(lostPets, blue);
  .createCategorySpecificClass(neighborlyMoment, neighborly);

  &__noCategory:hover {
    background-color: #d3dbe0;
  }

  &__userIcon {
    position: absolute;
    right: -17px;
  }

  &__mediaPreviewContainer {
    margin-left: 24px;
    border-radius: 3px;
    overflow: hidden;
    width: 60px;
    height: 34px;
  }

  &__previewContentContainer {
    margin-left: 16px;
    display: flex;
    flex: 1;
    flex-direction: row;
  }

  &__moderatedTitle {
    flex: 1;
    font-size: 13px;
    font-weight: 900;
    color: #3c485e;
    display: flex;
    align-items: center;
    word-break: break-word;
  }

  &__alertItemDecisionContainer {
    flex: 0.6;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__closeButtonContainer {
    z-index: 4;
    cursor: pointer;
    position: absolute;
    top: -12px;
    right: -12px;
    border-radius: 12px;
    width: 24px;
    height: 24px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.25);
    background-color: #f9fbfc;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  &__closeButtonIcon {
    height: 8px;
    width: 8px;
    -webkit-mask: url(./img/close_alert_ic.svg) no-repeat 100% 100%;
    mask: url(./img/close_alert_ic.svg) no-repeat 100% 100%;
    -webkit-mask-size: cover;
    mask-size: cover;
    background-color: #c5d0de;
  }

  &__closeButtonContainer:hover .closeButtonIcon {
    background-color: #7f8fa4;
  }

  &__timerContainer {
    width: 96px;
  }

  &__alertReasonLabel {
    margin-right: 24px !important;
  }
}

@primary-color: #47a9f2;@info-color: #47a9f2;@success-color: #71bc1f;@processing-color: #47a9f2;@error-color: #e75153;@highlight-color: #e75153;@warning-color: #fc9630;@normal-color: #7b838e;@font-family-no-number: 'Muli', sans-serif;@font-family: 'Muli', sans-serif;@code-family: 'Muli', sans-serif;@border-radius-base: 4px;@heading-color: #354052;@text-color: #354052;@text-color-secondary: #7b838e;@border-color-split: #d7d9dc;@heading-color-dark: #ffffff;@text-color-dark: #354052;@text-color-secondary-dark: fade(#fff, 65%);@font-size-base: 13px;@border-width-base: 2px;@badge-font-weight: 600;@badge-height: 24px;@btn-default-color: #7b838e;@btn-font-weight: 600;@btn-primary-color: #ffffff;@btn-primary-bg: #47a9f2;@btn-primary-border: #47a9f2;@label-color: #7b838e;@form-item-trailing-colon: false;@input-placeholder-color: #b9bcc3;@layout-body-background: #fbfbfc;@layout-header-background: #ffffff;@layout-footer-background: #ffffff;@layout-header-height: 46px;@layout-header-padding: 0 12px;@layout-footer-padding: 0 12px;@link-color: #92cef7;@link-active-color: #92cef7;@link-hover-color: #47a9f2;@link-decoration: none;@modal-mask-bg: rgba(53,64,82,0.65);