.alertItemDataContainer {
  display: flex;
  flex-direction: row;
  min-height: 403px;
  border-radius: 3px;
  background-color: #ffffff;
  padding-left: 48px;
}

.badgesBlock {
  margin-bottom: 16px;
  margin-top: 8px;
}

.contentColumn {
  flex-grow: 1;
  margin-top: 20px;
}

.compacteAlertItemDataContainer {
  min-height: 396px;
}

.decisionTimerContainer {
  min-width: 96px;
}

.decisionTimerContainer:empty {
  display: none;
}

.rsuReadContainer {
  min-width: 123px;
}

.dateContainer {
  max-width: 190px;
  font-family: Muli;
  font-size: 11px;
  font-weight: bold;
  text-align: left;
  color: #94a5ba;
  display: flex;
  justify-content: space-between;
}

.history {
  margin-top: 8px;
}

.dateTitle {
  width: 64px;
}

.dateValue {
  color: #3c485e;
}

.moderateContainer {
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
}

.closeEditModeButton,
.rsuReadButton {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: #c5d0de;
}

.closeEditModeButton:hover {
  color: #7f8fa4;
}

.rsuReadButton:hover {
  color: #7f8fa4;
}

.alertCloseButtonIcon,
.readIcon {
  height: 8px;
  width: 8px;
  -webkit-mask: url(./img/close_alert_ic.svg) no-repeat 100% 100%;
  mask: url(./img/close_alert_ic.svg) no-repeat 100% 100%;
  -webkit-mask-size: cover;
  mask-size: cover;
  background-color: #c5d0de;
}

.readIcon {
  height: 10px;
  width: 12px;
  -webkit-mask: url(./img/read_ic.svg) no-repeat 100% 100%;
  mask: url(./img/read_ic.svg) no-repeat 100% 100%;
}

.closeEditModeButton:hover .alertCloseButtonIcon {
  background-color: #7f8fa4;
}

.closeEditModeButton:hover .readIcon {
  background-color: #7f8fa4;
}

.historyHeader {
  display: flex;
  flex-direction: row;
  margin-bottom: 4px;
}

.userRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 8px;
}

.userEmail {
  margin-left: 5px;
  font-family: Muli;
  font-size: 11px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #1b1d1c;
}

@media (max-width: 1279px) {
  .alertItemDataContainer {
    margin-top: 2px;
    float: left;
    width: 100%;
    min-height: 0px;
  }
}
