.container {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  padding: 24px 0 32px;
}

.checkboxContainer {
  flex: 1;
}
