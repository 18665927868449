@import (reference) '~src/theme/colors.less';

.AlertCard {
  background-color: @white100;
  border-radius: 3px;
  box-shadow: 0 1px 2px 0 @grey10;
  flex-grow: 1;
  height: 100%;

  /* Preview and info in two lines */
  @media (max-width: 1199px) {
    &__detailsColumnContentWrapper {
      margin-left: 24px;
    }

    &__previewColumn &__media.NoMediaView {
      padding-bottom: 24px;
      padding-top: 48px;
    }
  }

  &__previewColumn {
    display: flex;
    justify-content: center;
    margin-left: 0;
    min-height: 300px;
  }

  &__previewColumn &__media {
    border-radius: 3px;
    max-height: 410px;
  }

  &__head {
    margin-bottom: 8px;
    margin-top: 24px;
    word-break: break-word;
  }

  &__title {
    font-size: 18px;
    font-weight: normal;
    letter-spacing: -0.3px;
  }

  &__additionalContent {
    display: flex;
    justify-content: flex-end;
    margin-right: 24px;
  }

  &__additional {
    text-align: right;
  }

  /* Preview and info in one line with timer on top */
  @media (min-width: 1200px) and (max-width: 1599px) {
    &__title {
      order: 2;
    }

    &__additional {
      order: 1;
    }

    &__additionalContent {
      justify-content: flex-start;
      margin-bottom: 16px;
    }

    &__detailsColumnContentWrapper {
      margin-right: 24px;
    }
  }

  &__description {
    display: inline-block;
    margin-bottom: 16px;
    word-break: break-word;
  }

  &__caseInfo {
    margin-bottom: 24px;
  }

  &__alertInfo {
    margin-bottom: 24px;
  }

  &__alertField:last-child {
    padding-bottom: 0;
  }

  &__info {
    margin-bottom: 16px;
  }

  &__freeNote {
    margin: 0 0 26px 0;
    padding: 0;
  }

  &__history {
    margin-bottom: 24px;
  }

  &__actions {
    margin-bottom: 32px;
  }

  &__actionsLayout {
    margin-right: 24px;
  }

  &__extendedDescription {
    display: flex;
    flex-direction: column;
    padding-bottom: 16px;
    word-break: break-word;
  }
}

@primary-color: #47a9f2;@info-color: #47a9f2;@success-color: #71bc1f;@processing-color: #47a9f2;@error-color: #e75153;@highlight-color: #e75153;@warning-color: #fc9630;@normal-color: #7b838e;@font-family-no-number: 'Muli', sans-serif;@font-family: 'Muli', sans-serif;@code-family: 'Muli', sans-serif;@border-radius-base: 4px;@heading-color: #354052;@text-color: #354052;@text-color-secondary: #7b838e;@border-color-split: #d7d9dc;@heading-color-dark: #ffffff;@text-color-dark: #354052;@text-color-secondary-dark: fade(#fff, 65%);@font-size-base: 13px;@border-width-base: 2px;@badge-font-weight: 600;@badge-height: 24px;@btn-default-color: #7b838e;@btn-font-weight: 600;@btn-primary-color: #ffffff;@btn-primary-bg: #47a9f2;@btn-primary-border: #47a9f2;@label-color: #7b838e;@form-item-trailing-colon: false;@input-placeholder-color: #b9bcc3;@layout-body-background: #fbfbfc;@layout-header-background: #ffffff;@layout-footer-background: #ffffff;@layout-header-height: 46px;@layout-header-padding: 0 12px;@layout-footer-padding: 0 12px;@link-color: #92cef7;@link-active-color: #92cef7;@link-hover-color: #47a9f2;@link-decoration: none;@modal-mask-bg: rgba(53,64,82,0.65);