.container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.searchByAlertContainer {
  flex: 1;
  flex-direction: row;
  display: flex;
}

.datePickerContainer {
  display: flex;
  flex-direction: row;
}

.csvButton {
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 36px;
  border-radius: 4px;
  background-image: linear-gradient(to bottom, #39b54a, #34aa44 98%);
  border: solid 1px #249533;
  font-family: Muli;
  font-size: 13px;
  font-weight: bold;
  text-align: left;
  color: #ffffff;
}

.csvButton:hover {
  background-image: linear-gradient(to bottom, #46c958, #3db84d 90%);
}
