.additionalMenuContainer {
  top: 0px;
  right: 0px;
  position: relative;
}

.menuButton {
  width: 21px;
  height: 21px;
  border-radius: 3px;
  background: no-repeat center / 3px url(./img/more_ic.svg) #e7eff7;
  cursor: pointer;
}

.menuButton:hover {
  background-color: #475263;
}

.activeMenuButton {
  background-color: #475263;
}

.menuContainer {
  position: absolute;
  top: 37px;
  width: 169px;
  z-index: 4;
}
