.outsideContainer {
  flex: 1;
  background-color: #eff3f6;
  padding-top: 1em;
}

.container {
  display: flex;
  max-width: 1280px;
  width: 1280px;
  margin: 0 auto;
  flex-direction: column;
}

.dateRangeContainer {
  display: flex;
  align-items: flex-end;
}

.dateRangeElement {
  margin: 0 10px;
}

.contentContainer {
  flex: 1;
  position: relative;
  min-height: 200px;
}

.button {
  margin-right: 20px;
  padding: 5px 10px;
  border: 1px solid #dedede;
}

.button:hover {
  cursor: pointer;
}

.contentMessageContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-family: Muli;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #354052;
  color: var(--light-black);
}

.datepickerIcon {
  display: inline-block;
  margin-right: 10px;
  margin-left: 10px;
  height: 16px;
  width: 16px;
  background: center no-repeat
    url('~src/components/date-picker/img/datepicker.svg');
}

.noResultsFound {
  text-align: center;
  font-family: 'Muli', sans-serif;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #354052;
  color: var(--light-black);
}
