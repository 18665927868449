.newsCurationItemWrapper {
  position: relative;
  margin: 0 65px 8px 16px;
}

.newsCurationItem {
  width: 100%;
  display: block;
  position: relative;
  font-family: 'Muli', sans-serif;
  background: #fff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  padding: 18px 17px;
  border-radius: 3px;
  cursor: pointer;
}
.newsCurationItem,
.newsCurationItem:hover,
.newsCurationItem:focus,
.newsCurationItem:active {
  color: #354052;
  text-decoration: none;
}

.newsCurationItem:hover {
  background-color: #e7e8ea;
}

.newsCurationItem__Header {
  align-items: center;
  display: flex;
  margin-bottom: 12px;
  white-space: nowrap;
}

.newsCurationItem__Source {
  display: inline-block;
  font-weight: bold;
  margin-left: 8px;
  margin-right: 12px;
  vertical-align: middle;
  text-transform: uppercase;
}

.newsCurationItem__Info {
  align-items: center;
  color: rgba(53, 64, 82, 0.35);
  display: inline-flex;
  font-size: 13px;
  font-weight: 600;
  overflow: hidden;
}

.newsCurationItem__user {
  color: #354052;
  margin-right: 8px;
  overflow: hidden;
}

.newsCurationItem__UserBlock {
  align-items: center;
  color: #354052;
  display: inline-flex;
  font-size: 13px;
  height: 100%;
  margin-left: 12px;
  margin-right: 8px;
}

.newsCurationItem__UserIcon {
  margin-right: 8px;
}

.newsCurationItem__Body {
}

.newsCurationItem__Body_Loader {
  min-height: 150px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: default;
}

.newsCurationItem__LoaderIcon {
  font-size: 40px;
}

.newsCurationItem_Loader {
  background: #fff !important;
}

.newsCurationItem__Title {
  font-family: 'Muli', sans-serif;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.22;
  letter-spacing: -0.3px;
  color: #354052;
  margin-bottom: 8px;
  word-break: break-word;
}

.newsCurationItem__NewsLink {
  margin-left: 16px;
}

.newsCurationItem__Content {
  font-family: 'Muli', sans-serif;
  font-size: 13px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.23;
  letter-spacing: normal;
  color: #354052;
  word-break: break-word;
}

.newsCurationItem__Controls {
}

.newsCurationItem__ControlsIcon {
  font-size: 18px;
  color: rgba(53, 64, 82, 0.2) !important;
  transition: color 0.2s linear;
}

.newsCurationItem__ControlsIcon:after {
  content: ' ';
  position: absolute;
  font-family: 'anticon';
  top: 0;
  left: 0;
}

.newsCurationItem__ControlsIcon_Active {
  color: #47a9f2;
}

.newsCurationItem__ControlsIcon_Active.newsCurationItem__ControlsIcon:after {
  content: '\E660';
  -webkit-animation: iconCheckboxEffect 0.36s ease-in-out;
  animation: iconCheckboxEffect 0.36s ease-in-out;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@keyframes iconCheckboxEffect {
  0% {
    transform: scale(1);
    opacity: 0.5;
  }
  100% {
    transform: scale(1.6);
    opacity: 0;
  }
}

.newsCurationItem__Controls > * {
  vertical-align: middle;
  margin-right: 32px;
}

.newsCurationItem__Controls_Left {
}

.newsCurationItem__Controls_Right {
}

.newsCurationItem__linkPlaceholder {
  display: inline-block;
  width: 16px;
  height: 16px;
  text-align: center;
}

.newsCurationItem__newsLinkIcon {
  font-size: 16px;
}

.newsCurationItem__brokenLink,
.newsCurationItem__brokenLink:active,
.newsCurationItem__brokenLink:hover:active,
.newsCurationItem__brokenLink:visited {
  color: #f1999a;
}
.newsCurationItem__brokenLink:hover {
  color: #e75153;
}
