.addCommentContainer {
  position: relative;
  display: flex;
  height: 48px;
  margin-top: 2px;
}

.buttonContainer {
  cursor: pointer;
  padding-left: 24px;
  display: flex;
  flex: 1;
  align-items: center;
  border-radius: 4px;
  background-color: #ffffff;
  font-family: Muli;
  font-size: 11px;
  font-weight: bold;
  line-height: 1.45;
  color: #fc9630;
}

.buttonContainer:hover {
  background-color: #dde6ec;
  color: #ff7f00;
}

.addCommentPopup {
  top: 49px;
  left: 10px;
}
