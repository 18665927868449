.alertContainer {
  width: 100%;
  border: solid 2px;
  border-color: rgba(255, 255, 255, 0);
  margin-bottom: 16px;
}

.editingContainer {
  border-color: #ffd202;
  border-radius: 4px;
}

.assignedContainer {
  border-color: #5ec95b;
  border-radius: 4px;
}

.alertItemContainer {
  display: flex;
  background-color: #ffffff;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
