.pageContentWrapper {
  background: #f9f9f9;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  -webkit-box-flex: 1;
  -webkit-flex: auto;
  -ms-flex: auto;
  flex: auto;
}
.pageTitle {
  margin: 20px 0;
}
.pageContent {
  margin: 20px 48px;
}
.pageTitle {
  font-size: 21px;
}
.tableContent {
  background: #fff;
  .ant-table-thead
    > tr
    > th.ant-table-column-has-actions.ant-table-column-has-sorters:hover {
    background: transparent;
  }
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    color: #7c838e;
    border-bottom: 1px solid #e8e8e8;
  }
  .ant-table-thead > tr > th {
    font-size: 14px;
    background: #fff;
    border-bottom: 2px solid #e8e8e8;
  }
  .ant-table-pagination.ant-pagination {
    margin-left: 16px;
    margin-right: 16px;
  }
}
.tableWordsCol {
  .ant-table-thead > tr > th {
  }
}
.tableWordsCategory {
}
.tableWordsCategory_forbidden,
.tableWordsCategory_alerts-forbidden {
  color: #df6f00;
}

.tableWordsWord {
  &:before {
    content: '';
    width: 8px;
    height: 8px;
    border-radius: 100%;
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
  }
  .ant-checkbox-wrapper {
    span {
      vertical-align: middle;
    }
    span:not(.ant-checkbox) {
      overflow: hidden;
      display: inline-block;
      max-width: 200px;
    }
  }
}
.tableWordsNewWordIndicator {
  &:before {
    content: '';
    background: #47a9f2;
  }
}

.filterField {
}

// Left Col
.LeftColumnAllWordsPageTitle {
  margin-top: 30px;
}
.leftColumnFieldWordsPage {
  margin-bottom: 14px !important;
}

@primary-color: #47a9f2;@info-color: #47a9f2;@success-color: #71bc1f;@processing-color: #47a9f2;@error-color: #e75153;@highlight-color: #e75153;@warning-color: #fc9630;@normal-color: #7b838e;@font-family-no-number: 'Muli', sans-serif;@font-family: 'Muli', sans-serif;@code-family: 'Muli', sans-serif;@border-radius-base: 4px;@heading-color: #354052;@text-color: #354052;@text-color-secondary: #7b838e;@border-color-split: #d7d9dc;@heading-color-dark: #ffffff;@text-color-dark: #354052;@text-color-secondary-dark: fade(#fff, 65%);@font-size-base: 13px;@border-width-base: 2px;@badge-font-weight: 600;@badge-height: 24px;@btn-default-color: #7b838e;@btn-font-weight: 600;@btn-primary-color: #ffffff;@btn-primary-bg: #47a9f2;@btn-primary-border: #47a9f2;@label-color: #7b838e;@form-item-trailing-colon: false;@input-placeholder-color: #b9bcc3;@layout-body-background: #fbfbfc;@layout-header-background: #ffffff;@layout-footer-background: #ffffff;@layout-header-height: 46px;@layout-header-padding: 0 12px;@layout-footer-padding: 0 12px;@link-color: #92cef7;@link-active-color: #92cef7;@link-hover-color: #47a9f2;@link-decoration: none;@modal-mask-bg: rgba(53,64,82,0.65);