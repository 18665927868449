.confirmPopupContainer {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.popupWindow {
  width: 362px;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 2px 7px 0 rgba(127, 143, 164, 0.5);
  padding: 42px 40px 36px 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.infoText {
  font-family: Muli;
  font-size: 18px;
  text-align: center;
  color: #354052;
  padding: 0px 40px 0px 40px;
}

.buttonsContainer {
  margin-top: 37px;
  display: flex;
  flex-direction: row;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
}

.confirmButton,
.cancelButton {
  border-radius: 4px;
  padding-top: 11px;
  padding-bottom: 11px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  user-select: none;
}

.confirmButton {
  background-color: #e75153;
  color: #ffffff;
  flex: 1.6;
}

.confirmButton:hover {
  background-color: #d04042;
}

.cancelButton {
  flex: 1;
  margin-left: 8px;
  color: #7f8fa4;
  background-color: #c5d0de;
}

.cancelButton:hover {
  color: #7f8fa4;
  background-color: #b2bbc7;
}
