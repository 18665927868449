.assignButtonContainer {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 3;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  color: #ffffff;
  background-color: rgba(255, 255, 255, 0.8);
  user-select: none;
  opacity: 0;
  transition: opacity 230ms ease-out;
}

.assignButtonContainer:hover {
  opacity: 1;
}

.assignButtonContainer:hover .button {
  visibility: visible;
}

.button {
  visibility: hidden;
  padding: 12px 66px 12px 66px;
  border-radius: 3px;
  cursor: pointer;
}

.assignButton {
  background-color: #7ed321;
}

.assignButton:hover {
  background-color: #77ca1d;
}

.editButton {
  background-color: #ffd101;
}

.editButton:hover {
  background-color: #efba00;
}

.loading {
  position: relative;
  pointer-events: none;
  cursor: default;
  text-decoration: none;
  background-color: grey;
  overflow: hidden;
}

.loading:before {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 5px;
  background-color: #e5f0fd;
  content: '';
}

.loading:after {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 5px;
  width: 10%;
  background-color: #cadfff;
  content: '';
  animation: loading 1s ease infinite;
}

@keyframes loading {
  from {
    left: 0;
  }
  to {
    left: 100%;
  }
}
