.container {
  z-index: 2;
  user-select: none;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  border-radius: 3px;
  padding: 0 60px;
}

.orange {
  background-image: linear-gradient(to bottom, #f38b33, #f7621c);
  color: #ffffff;
}

.orangeActive {
  background-image: linear-gradient(to bottom, #f59847, #f37030);
}

.orange:hover {
  background-image: linear-gradient(to bottom, #f59847, #f37030);
}

.orange:active {
  background-image: linear-gradient(to bottom, #f2903c, #f56824);
}

.blue {
  background-image: linear-gradient(to bottom, #1cb4f7, #1898d5);
  color: #ffffff;
}

.blueActive {
  background-image: linear-gradient(to bottom, #2bb7f6, #219ed8);
}

.blueActive:hover {
  background-image: linear-gradient(to bottom, #2bb7f6, #219ed8);
}

.blue:hover {
  background-image: linear-gradient(to bottom, #2bb7f6, #219ed8);
}

.blue:active {
  background-image: linear-gradient(to bottom, #1cb4f7, #1898d5);
}

.white {
  background-image: linear-gradient(to top, #f2f4f7, #ffffff);
  border: solid 1px #e9e9e9;
  color: #354052;
}

.whiteActive {
  background-image: linear-gradient(to top, #1aa9e9, #67cfff);
}

.white:hover {
  background-image: linear-gradient(to top, #eaeaec, #ffffff);
}

.white:active {
  background-image: linear-gradient(to top, #f2f4f7, #ffffff);
  border: solid 1px #e9e9e9;
}

.whiteActive:hover {
  background-image: linear-gradient(to top, #1aa9e9, #67cfff);
}
