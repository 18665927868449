.moreButton {
  cursor: pointer;
  font-family: Muli;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.45;
  letter-spacing: normal;
  text-align: left;
  color: #1998d5;
}

.moreButtonPadding {
  margin-top: 8px;
}
