/* vietnamese */
@font-face {
  font-family: 'Muli';
  font-style: normal;
  font-weight: 400;
  src: local('Muli Regular'), local('Muli-Regular'), url(~src/theme/fonts/7Auwp_0qiz-afT3GLQjUwkQ1OQ.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Muli';
  font-style: normal;
  font-weight: 400;
  src: local('Muli Regular'), local('Muli-Regular'), url(~src/theme/fonts/7Auwp_0qiz-afTzGLQjUwkQ1OQ.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Muli';
  font-style: normal;
  font-weight: 400;
  src: local('Muli Regular'), local('Muli-Regular'), url(~src/theme/fonts/7Auwp_0qiz-afTLGLQjUwkQ.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* vietnamese */
@font-face {
  font-family: 'Muli';
  font-style: normal;
  font-weight: 600;
  src: local('Muli SemiBold'), local('Muli-SemiBold'), url(~src/theme/fonts/7Au_p_0qiz-ade3iOCr2z24PMFk-0g.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Muli';
  font-style: normal;
  font-weight: 600;
  src: local('Muli SemiBold'), local('Muli-SemiBold'), url(~src/theme/fonts/7Au_p_0qiz-ade3iOCv2z24PMFk-0g.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Muli';
  font-style: normal;
  font-weight: 600;
  src: local('Muli SemiBold'), local('Muli-SemiBold'), url(~src/theme/fonts/7Au_p_0qiz-ade3iOCX2z24PMFk.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* vietnamese */
@font-face {
  font-family: 'Muli';
  font-style: normal;
  font-weight: 700;
  src: local('Muli Bold'), local('Muli-Bold'), url(~src/theme/fonts/7Au_p_0qiz-adYnjOCr2z24PMFk-0g.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Muli';
  font-style: normal;
  font-weight: 700;
  src: local('Muli Bold'), local('Muli-Bold'), url(~src/theme/fonts/7Au_p_0qiz-adYnjOCv2z24PMFk-0g.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Muli';
  font-style: normal;
  font-weight: 700;
  src: local('Muli Bold'), local('Muli-Bold'), url(~src/theme/fonts/7Au_p_0qiz-adYnjOCX2z24PMFk.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* vietnamese */
@font-face {
  font-family: 'Muli';
  font-style: normal;
  font-weight: 800;
  src: local('Muli ExtraBold'), local('Muli-ExtraBold'), url(~src/theme/fonts/7Au_p_0qiz-adZXgOCr2z24PMFk-0g.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Muli';
  font-style: normal;
  font-weight: 800;
  src: local('Muli ExtraBold'), local('Muli-ExtraBold'), url(~src/theme/fonts/7Au_p_0qiz-adZXgOCv2z24PMFk-0g.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Muli';
  font-style: normal;
  font-weight: 800;
  src: local('Muli ExtraBold'), local('Muli-ExtraBold'), url(~src/theme/fonts/7Au_p_0qiz-adZXgOCX2z24PMFk.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* vietnamese */
@font-face {
  font-family: 'Muli';
  font-style: normal;
  font-weight: 900;
  src: local('Muli Black'), local('Muli-Black'), url(~src/theme/fonts/7Au_p_0qiz-adbHhOCr2z24PMFk-0g.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Muli';
  font-style: normal;
  font-weight: 900;
  src: local('Muli Black'), local('Muli-Black'), url(~src/theme/fonts/7Au_p_0qiz-adbHhOCv2z24PMFk-0g.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Muli';
  font-style: normal;
  font-weight: 900;
  src: local('Muli Black'), local('Muli-Black'), url(~src/theme/fonts/7Au_p_0qiz-adbHhOCX2z24PMFk.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
* {
  font-weight: 600;
}
.ant-btn {
  font-size: 14px;
}
/* Ant Design theme tweaks */
.ant-input,
.ant-select-lg,
.ant-form label {
  font-size: 13px !important;
  font-family: 'Muli', sans-serif !important;
}
.ant-form-item-label {
  padding-bottom: 1px !important;
}
.ant-form label {
  color: #7b838e !important;
}
/*TODO move to antd customization*/
body .ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled),
body .ant-select-open .ant-select-selection,
body .ant-input,
body .ant-input:focus,
body .ant-input:hover,
body .ant-select-selection,
body .ant-select-selection:focus,
body .ant-select-selection:hover {
  border-width: 2px !important;
}
body .ant-select-selection--multiple .ant-select-selection__choice {
  border-color: transparent;
  font-weight: 500;
}
.ant-btn:not(.ant-btn-primary):not(.ant-btn-link) {
  border: 2px rgba(53, 64, 82, 0.2) solid;
}
.ant-btn {
  font-family: Muli, sans-serif;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
}
.ant-layout-header {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  box-sizing: content-box;
  padding: 0;
  z-index: 1;
}
.ant-layout-content {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: auto;
  -webkit-box-flex: 1;
  -webkit-flex: auto;
  -ms-flex: auto;
  flex: auto;
}
.ant-btn-danger {
  background: #fff;
}
.ant-btn-danger:not([disabled]):not(:hover) {
  background-color: #ffffff;
  color: #e75153;
}
.ant-btn-danger:not([disabled]):hover {
  background-color: #f57f7d;
}
.ant-modal-title {
  width: 496px;
  height: 22px;
  font-family: Muli, sans-serif;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.22;
  letter-spacing: -0.3px;
  color: #354052;
}
.ant-modal-footer {
  background-color: rgba(53, 64, 82, 0.03);
  padding: 13px 24px;
}
/*Label*/
.ant-select-selection--multiple .ant-select-selection__choice {
  background: #eaebed;
}
.ant-form-item-label {
  line-height: 24.9999px;
  padding: 0 0 2px;
}
.ant-form-vertical .ant-form-item {
  margin-bottom: 4px;
}
.ant-form label {
  font-size: 13px;
}
.ant-upload.ant-upload-drag {
  border-style: solid;
  font-weight: 600;
  background-color: #fbfbfc;
}
.ant-upload.ant-upload-drag .ant-upload-text {
  color: #7b838e;
}
.ant-upload.ant-upload-drag a {
  color: #47a9f2;
}
.ant-upload.ant-upload-drag.ant-upload-disabled a {
  cursor: not-allowed;
}
#root {
  min-width: 1136px;
  height: 100%;
}
h2,
h3,
h4,
h5 {
  font-weight: normal;
}
.container {
  height: 100%;
  width: 100%;
  position: relative;
  flex-direction: column;
  display: flex;
}
@media (max-width: 1280px) {
  .container {
    height: 100%;
    width: 100%;
    position: relative;
    flex-direction: column;
    display: flex;
  }
}
.menu-item {
  margin: 16px;
  user-select: none;
  font-family: Muli;
  font-size: 13px;
  font-weight: 600;
  line-height: 1.29;
  text-align: left;
  color: #7f8fa4;
}
.linked-menu-item {
  cursor: pointer;
}
.linked-menu-item:hover {
  color: #354052;
}
.linked-menu-item:active {
  color: #7f8fa4;
}
.active-menu-item {
  color: #7f8fa4;
}
.active-menu-item:hover {
  color: #354052;
}
body {
  width: 100vw;
  height: 100vh;
  min-width: 720px;
  font-size: 13px;
  margin: 0;
  padding: 0;
  border: 0;
  font-family: Muli, sans-serif;
}
.scroll::-webkit-scrollbar-track {
  background-color: #ddf0f5;
}
.scroll::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  background-color: #f5f5f5;
}
.scroll::-webkit-scrollbar-thumb {
  background-color: #68bae1;
}
.Select-value {
  background-color: #f4f7f9;
  border: solid 1px #e6eaee;
  color: #7f8fa4;
}
#root {
  display: flex;
  height: 100%;
  /*min-width: 1280px;*/
  width: 100%;
}
.clear {
  clear: both;
}
.row {
  margin-left: 1.2em;
  margin-right: 1.2em;
}
.row-double {
  margin-left: 2.4em;
  margin-right: 2.4em;
}
.row-padding {
  padding-left: 1.2em;
  padding-right: 1.2em;
}
.right-row {
  margin-left: 1.2em;
}
.right-row-double {
  margin-left: 2.4em;
}
.left-row {
  margin-right: 1.2em;
}
.left-row-double {
  margin-right: 2.4em;
}
.column {
  margin-top: 0.8em;
  margin-bottom: 0.8em;
}
.column-double {
  margin-top: 1.6em;
  margin-bottom: 1.6em;
}
.column-padding {
  padding-top: 0.8em;
  padding-bottom: 0.8em;
}
.bottom-column {
  margin-top: 0.8em;
}
.bottom-column-double {
  margin-top: 1.6em;
}
.top-column {
  margin-bottom: 0.8em;
}
.top-column-double {
  margin-bottom: 1.6em;
}
:root {
  --col: 70px;
  --gap: 40px;
}
.rsu-label {
  width: 37px;
  height: 18px;
  border-radius: 3px;
  background-color: #ff7f00;
  border: solid 0.7px #e77300;
  font-family: 'Muli', sans-serif;
  font-size: 9px;
  font-weight: 900;
  line-height: 1.78;
  text-align: left;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}
