.container {
  display: flex;
  flex-direction: column;
  position: relative;
}

.input {
  display: flex;
  flex: 1;
  border-radius: 2px;
  background-color: #ffffff;
  border: solid 1px #e6eaee;
  padding: 10px;
  font-family: Muli;
  font-size: 13px;
  letter-spacing: -0.2px;
  text-align: left;
  color: #000000;
}

.buttons {
  display: flex;
  flex-direction: row;
  z-index: 2;
}

.buttonTitle {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  font-family: Muli;
  font-size: 15px;
  font-weight: bold;
  letter-spacing: -0.2px;
  text-align: left;
  color: #ffffff;
}

.buttonContainer {
  flex: 1;
}

.submitButtonContainer {
  display: flex;
  z-index: 0;
}

.submitButton {
  display: flex;
  width: 160px;
  font-family: Muli;
  font-size: 15px;
  font-weight: bold;
  letter-spacing: -0.2px;
  text-align: left;
  color: #4a4a4a;
  justify-content: center;
  align-items: center;
}

.selectContainer {
  display: flex;
  flex-direction: column;
}

.selectTitle {
  font-family: Muli;
  font-size: 13px;
  font-weight: bold;
  line-height: 0.69;
  text-align: left;
  color: #7f8fa4;
}

.menuContainer {
  z-index: 1;
  position: absolute;
  padding-top: 80px;
  left: 0px;
  right: 0px;
  top: 0px;
}

.reasonsContainer {
  display: flex;
  flex-direction: row;
}

.reasonsRow {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.menuItem {
  user-select: none;
  cursor: pointer;
  font-family: Muli;
  font-size: 13px;
  font-weight: bold;
  text-align: left;
  color: #7f8fa4;
}

.menuItem:hover {
  color: #9eaab8;
}

.menuItem:active {
  color: #00a2c5;
}

.activeMenuItem {
  color: #00a2c5;
}

.activeMenuItem:hover {
  color: #00a2c5;
}

.category {
  margin-bottom: 10px;
}

.labelGrp {
  margin: 5px 0 5px 20px;
}

.label {
  position: relative;
  font-family: 'Muli';
  font-size: 13px;
  font-weight: bold;
  text-align: left;
  color: #7f8fa4;
  margin: 4px 0;
}

.label span {
  display: inline-block;
  margin-left: 20px;
}

.label input {
  opacity: 0;
  width: 0;
  height: 0;
}

.fakeRadio {
  position: absolute;
  left: 0;
  top: 0;
  width: 15px;
  height: 15px;
  border: solid 2px #dde6ec;
  border-radius: 15px;
}
.fakeRadio:after {
  position: absolute;
  width: 9px;
  height: 9px;
  border-radius: 10px;
  background-color: #fff;
  left: 3px;
  top: 3px;
  content: '';
}
.fakeCheckbox {
  position: absolute;
  left: 0;
  top: 0;
  width: 15px;
  height: 15px;
  border: solid 2px #dde6ec;
  border-radius: 3px;
}
.fakeCheckbox:after {
  content: '';
  position: absolute;
  left: 5px;
  top: 2px;
  width: 4px;
  height: 7px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.groupContainer {
  width: 50%;
}

.label input:checked ~ .fakeCheckbox,
.label input:checked ~ .fakeRadio {
  background: #1991eb;
  border-color: #1991eb;
}

.categoryTitle {
  margin: 0;
}

.categoryContainer {
  display: flex;
  flex-wrap: wrap;
  overflow: auto;
  margin-top: 20px;
}

.categoryContainer > div {
  width: 50%;
  float: left;
}
.modalHeader {
  padding: 18px 0;
  text-align: center;
  font-size: 18px;
  color: #354052;
  background-color: #eff3f6;
  box-shadow: 0 1px 0 0 rgba(206, 210, 229, 0.5);
}
.modalContainer {
  padding: 24px 32px;
}
.buttonContainer {
  display: flex;
}
.button {
  padding: 12px 56px;
  font-family: 'Muli';
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  color: #94a5ba;
  border-radius: 4px;
  margin-right: 31px;
  border: solid 2px #ced2e5;
}

.button:hover {
  cursor: pointer;
}

.button.disabled {
  background-color: #ffffff;
  cursor: not-allowed;
}

.footer {
  padding: 0 30px 30px;
  background-color: rgba(239, 243, 246, 0.5);
}
