:root {
  --paddingSide: 16px;
  --paddingTop: 25px;
}

.container {
  box-sizing: border-box;
  width: 370px;
  padding: var(--paddingTop) 0;
  flex-direction: column;
  display: flex;
  justify-content: center;
  position: absolute;
  z-index: 1000;
  top: 10px;
  left: -10px;
  border-radius: 2px;
  background-color: #f4f7f9;
  border: solid 1px #dde6ec;
}
.submitButton {
  margin-top: 20px;
  height: 42px;
  border-radius: 4px;
  background-color: #7ed321;
  background-image: none;
  font-family: Muli;
  font-size: 13px;
  font-weight: bold;
  text-align: center;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.submitButton:hover {
  background-color: #7ed321;
  background-image: none;
}

.flag {
  margin-left: 10px;
}

.flaggedContainer {
  border-top: solid 1px #dde6ec;
  margin-top: 15px;
  padding: 10px 15px 0;
}

.onRsuContainer {
  margin-top: 15px;
  padding: 10px 15px 0;
}

.resolvedContainer {
  margin-top: 15px;
  padding: 10px 15px 0;
}

.deletedContainer {
  margin-top: 15px;
  padding: 10px 15px 0;
}
