.NewNewsBlock {
  padding: 0 65px 0 16px;

  &__button {
    width: 100%;
    height: 45px;
    background-color: #e4e5e7;
    color: #7a828d;
    border: none;
    //font-family: Muli, sans-serif;
    font-size: 13px;
    font-weight: 600;
    //font-style: normal;
    //font-stretch: normal;
    line-height: 1.23;
    //letter-spacing: normal;
    text-align: center;
    cursor: pointer;
    position: relative;
    overflow: hidden;

    &:focus,
    &:active {
      outline: none;
    }
  }

  &__text {
    text-align: center;

    &:hover {
      background-color: rgba(53, 64, 82, 0.15);
    }
  }

  &__info {
    color: #abafb7;
    text-align: right;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 10px 0 0;
  }
}

@primary-color: #47a9f2;@info-color: #47a9f2;@success-color: #71bc1f;@processing-color: #47a9f2;@error-color: #e75153;@highlight-color: #e75153;@warning-color: #fc9630;@normal-color: #7b838e;@font-family-no-number: 'Muli', sans-serif;@font-family: 'Muli', sans-serif;@code-family: 'Muli', sans-serif;@border-radius-base: 4px;@heading-color: #354052;@text-color: #354052;@text-color-secondary: #7b838e;@border-color-split: #d7d9dc;@heading-color-dark: #ffffff;@text-color-dark: #354052;@text-color-secondary-dark: fade(#fff, 65%);@font-size-base: 13px;@border-width-base: 2px;@badge-font-weight: 600;@badge-height: 24px;@btn-default-color: #7b838e;@btn-font-weight: 600;@btn-primary-color: #ffffff;@btn-primary-bg: #47a9f2;@btn-primary-border: #47a9f2;@label-color: #7b838e;@form-item-trailing-colon: false;@input-placeholder-color: #b9bcc3;@layout-body-background: #fbfbfc;@layout-header-background: #ffffff;@layout-footer-background: #ffffff;@layout-header-height: 46px;@layout-header-padding: 0 12px;@layout-footer-padding: 0 12px;@link-color: #92cef7;@link-active-color: #92cef7;@link-hover-color: #47a9f2;@link-decoration: none;@modal-mask-bg: rgba(53,64,82,0.65);