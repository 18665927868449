@white100: #ffffff;

@red100: #e75153;
@red65: #f1999a;
@red35: #f8c9ca;
@red20: #fbe1e1;
@red10: #fdeeee;

@orange100: #fc9630;
@orange65: #fdc383;

@grey100: #354052;
@grey65: #7b838e;
@grey35: #b9bcc3;
@grey20: #d7d9dc;
@grey10: #eaebed;
@grey3: #fbfbfc;
@greyOverlay: fade(@grey100, 65);

@blue100: #47a9f2;
@blue65: #92cef7;
@blue35: #c6e5fc;
@blue20: #dff0fc;
@blue10: #eef7fd;

@purple100: #6c4fc5;
@purple65: #a997de;
@purple35: #d2c9ee;
@purple20: #e6e0f5;
@purple10: #f0edf9;

@greenDark: #71bc1f;
@green100: #7ed321;
@green65: #b4e679;
@green35: #d7f2ba;
@green20: #e8f7d8;
@green10: #f2fbe9;

@yellow100: #fdd835;
@yellow65: #fde57b;
@yellow10: #fefbea;

@neighborly65: #c5c5c5;
@neighborly10: #f7f7f7;
