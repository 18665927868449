.freeNoteContainer {
  padding-left: 15px;
}

.freeNoteText {
  padding: 12px 16px 12px 16px;
  border-radius: 4px;
  background-color: #f7f9fa;
  word-break: break-word;
}
