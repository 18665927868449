.NewsCuration__List {
  height: 100%;
  padding: 13px 0 0 0;
}

:global(.ReactVirtualized__List) {
  outline: none;
}

.resultsFound__block {
  text-align: left;
  font-weight: bold;
  vertical-align: middle;
  font-size: 16px;
  padding: 10px 20px;
}

.NewsList__wrapper {
  flex-grow: 1;
  margin-top: 8px;
}
