.container {
  padding: 16px 16px 16px 16px;
  top: 26px;
  border-radius: 4px;
  margin-bottom: 20px;
  width: 450px;
  position: absolute;
  background-color: #ffffff;
  filter: drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.38));
  z-index: 10;
}

.deleteContainer {
  display: flex;
  flex-direction: column;
}
.commentText {
  padding: 2px 2px 3px;
  position: relative;
}
.invisible {
  opacity: 0;
}
.textarea {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  min-height: 17px;
  height: 100%;
  border: none;
  resize: none;
  font-family: Muli;
  font-size: 15px;
  font-weight: 600;
}
.textarea:focus,
.textarea:active {
  outline: none;
}
.textContainer {
  float: left;
  display: flex;
  flex-direction: row;
  font-family: Muli;
  font-size: 15px;
  font-weight: 600;
  line-height: 1.33;
  text-align: left;
  color: #354052;
}
.input {
  min-height: 80px;
  resize: none;
  flex: 1;
  padding: 0;
  background-color: transparent;
  border: 0px solid;
  font-family: Muli;
  font-size: 13px;
  letter-spacing: -0.2px;
  text-align: left;
  color: #354052;
}

.input:focus {
  outline: none;
}
.content {
  flex: 1;
  margin-left: 16px;
}

.toolsContainer {
  float: right;
  display: flex;
  justify-content: flex-end;
}

.closeButton {
  font-family: Muli;
  font-size: 13px;
  font-weight: 600;
  line-height: 1.54;
  text-align: right;
  color: #354052;
  cursor: pointer;
}

.deleteButton {
  cursor: pointer;
  height: 16px;
  width: 16px;
  background: no-repeat center / 19px url(./img/ic_delete.svg);
}

.rsuLabel {
  width: 37px;
  height: 18px;
  border-radius: 3px;
  background-color: #ff7f00;
  border: solid 0.7px #e77300;
  font-family: Muli;
  font-size: 9px;
  font-weight: 900;
  line-height: 1.78;
  text-align: left;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.disabledSendButton {
  border: solid 2px #dde6ec;
  color: #c5d0de;
}

.activeSendButton {
  cursor: pointer;
  color: #94a5ba;
  border: solid 2px #94a5ba;
}

.sendButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 32px;
  border-radius: 4px;
  font-family: Muli;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
}

.activeSendButton:hover {
  border: solid 2px #c5d0de;
  background-color: #c5d0de;
  color: #8a99ad;
}

.containerArrow {
  position: absolute;
  top: -12px;
  width: 0px;
  height: 0px;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 12px solid #ffffff;
}

.containerArrow.editArrow {
  left: 65px;
}

.commentDate {
  font-family: Muli;
  font-size: 13px;
  font-weight: 600;
  text-align: left;
  color: #94a5ba;
}

.confirmationContainer {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: row;
}
.submitButton {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 32px;
  border-radius: 4px;
  font-family: Muli;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
}

.deleteConfirmButton {
  border: solid 2px #dde6ec;
  color: #e85053;
}

.deleteConfirmButton:hover {
  border: solid 2px #e85053;
  background-color: #e85053;
  color: #ffffff;
}

.editConfirmButton {
  border: solid 2px #dde6ec;
  color: #2594e7;
}

.editConfirmButton:hover {
  border: solid 2px #2594e7;
  background-color: #2594e7;
  color: #ffffff;
}

.cancelButton {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 32px;
  border-radius: 4px;
  border: solid 2px #e0e8ee;
  background-color: #e0e8ee;
  font-family: Muli;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  color: #8594a8;
}

.cancelButton:hover {
  background-color: #ced7e4;
  border: solid 2px #ced7e4;
  color: #7f8ea4;
}
