.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  position: relative;
}

.decorateContainer {
  z-index: 2;
  user-select: none;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  height: 42px;
  border-radius: 4px;
  background-blend-mode: multiply;
}

.decorateContainer:hover {
  background-blend-mode: multiply;
}

.userNameField {
  font-family: Muli;
  font-size: 13px;
  font-weight: bold;
  text-align: left;
  color: #354052;
  display: flex;
  align-items: center;
}

.dropdownArrow {
  width: 24px;
  background: no-repeat center / 24px url(./img/chevron-down.svg);
}

.devideLine {
  height: 1px;
  background-color: #dde6ec;
}

.menuContainer {
  z-index: 5;
  position: absolute;
  left: 0px;
  right: 0px;
  top: 55px;
}
