@import (reference) '~src/theme/colors.less';

.AlertActions {
  display: inline-flex;
  align-items: center;

  &__assigned {
    border-color: @purple100;
  }

  &__delimiter {
    background-color: @grey35;
    display: inline-block;
    height: 19px;
    margin-left: 12px;
    margin-right: 12px;
    width: 1px;
  }

  &__linkApprove {
    font-size: 18px;
  }

  &__linkDecline {
    color: @orange65;
    font-size: 18px;

    &:active {
      color: @orange65;
    }

    &:hover {
      color: @orange100;
    }
  }

  &__dropdown {
    margin-left: 12px;
  }

  &__dropdownOpenButton {
    border-color: @grey20;
    border-width: 1px !important;
    height: 24px;
    width: 24px;

    &.ant-btn {
      padding: 0;
    }
  }

  &__droprownOpenIcon {
    height: 24px;
    width: 24px;
  }

  &__toggle {
    margin: auto;
  }
}

@primary-color: #47a9f2;@info-color: #47a9f2;@success-color: #71bc1f;@processing-color: #47a9f2;@error-color: #e75153;@highlight-color: #e75153;@warning-color: #fc9630;@normal-color: #7b838e;@font-family-no-number: 'Muli', sans-serif;@font-family: 'Muli', sans-serif;@code-family: 'Muli', sans-serif;@border-radius-base: 4px;@heading-color: #354052;@text-color: #354052;@text-color-secondary: #7b838e;@border-color-split: #d7d9dc;@heading-color-dark: #ffffff;@text-color-dark: #354052;@text-color-secondary-dark: fade(#fff, 65%);@font-size-base: 13px;@border-width-base: 2px;@badge-font-weight: 600;@badge-height: 24px;@btn-default-color: #7b838e;@btn-font-weight: 600;@btn-primary-color: #ffffff;@btn-primary-bg: #47a9f2;@btn-primary-border: #47a9f2;@label-color: #7b838e;@form-item-trailing-colon: false;@input-placeholder-color: #b9bcc3;@layout-body-background: #fbfbfc;@layout-header-background: #ffffff;@layout-footer-background: #ffffff;@layout-header-height: 46px;@layout-header-padding: 0 12px;@layout-footer-padding: 0 12px;@link-color: #92cef7;@link-active-color: #92cef7;@link-hover-color: #47a9f2;@link-decoration: none;@modal-mask-bg: rgba(53,64,82,0.65);