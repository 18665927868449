.container {
  display: flex;
  flex-direction: row;
}

.pageChunksDevider {
  margin-left: 3px;
  margin-right: 3px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.defaultButtonSize {
  width: 43px;
  height: 40px;
}

.defaultButtonText {
  font-size: 13px;
  font-weight: 600;
  text-align: center;
  color: #7f8fa4;
}

.defaultButton {
  cursor: pointer;
  font-family: Muli;
  border-radius: 2px;
  background-color: #ffffff;
  border: solid 1px #e6eaee;
  display: flex;
  justify-content: center;
  align-items: center;
}

.activeButton {
  cursor: default;
  background-color: rgba(0, 0, 0, 0);
  border-color: #1998d5;
  color: #1998d5;
}

.disabledButton {
  cursor: default;
}

.pageButton {
  margin-left: 3px;
  margin-right: 3px;
}

.icon {
  opacity: 0.5;
  width: 16px;
  height: 16px;
  background-repeat: no-repeat;
  background-position: center;
}

.disabledIcon {
  opacity: 0.1;
}

.previousIcon {
  background-image: url('./img/previous_ic.svg');
}

.nextIcon {
  background-image: url('./img/next_ic.svg');
}
