.fixedContainer {
  background-color: #fff;
  margin-bottom: 0;
  transition: all 0.2s linear;
  position: relative;
  z-index: 3;
}
.headerScrolled {
  border-bottom: none;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
}

.logo {
  width: 26px;
  height: 26px;
  background: no-repeat center / contain url(./img/admin-logo.svg);
  margin: auto;
}

.logoText {
  width: 172px;
  height: 24px;
  font-family: Muli, sans-serif;
  font-size: 21px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: #354052;
  margin: auto 24px auto 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.emptyBox {
  display: flex;
  flex: 1;
}

.container {
  flex-direction: row;
  display: flex;
  height: 46px;
  margin: 0;
}
