.mainContainer {
  display: flex;
  flex-direction: row;
}

.checkboxContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.check {
  height: 24px;
}

.container {
  width: 0px;
  border-radius: 4px;
  background-color: #ffffff;
  border: solid 1px #ced2e5;
  margin-bottom: 12px;
  flex: 1;
}

.name {
  font-weight: bold;
  color: #1b1d1c;
  width: 90px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.header {
  position: relative;
  display: flex;
  align-items: center;
  background-color: #f9fbfc;
  border-bottom: solid 1px #ced2e5;
  padding: 22px 24px;
  border-radius: 4px 4px 0 0;
  align-items: flex-start;
}
.statistic {
  margin-top: 10px;
  display: flex;
}

.history {
  width: calc(var(--col) * 5 + var(--gap) * 4);
}

.statElement {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 5px;
}

.statHeader {
  background-color: #eff3f6;
  width: 52px;
  text-align: center;
  padding: 10px 0 6px;
  border-radius: 4px;
}

.statHeader img {
  width: 12px !important;
}
.statContent {
  color: #94a5ba;
}
.headerImg.empty {
  margin: 0;
}

.headerImg.big {
  width: calc(var(--col) * 3 + var(--gap) * 2);
  margin-right: var(--gap);
}

.headerImg.extraBig {
  width: calc(var(--col) * 6 + var(--gap) * 5);
  margin-right: var(--gap);
}

.searchAlertMultimediaViewer.fullheight {
  height: calc(100vh - 150px);
}

.hide {
  display: none !important;
}

.alertContent.expanded:after {
  display: none;
}
.avatar img {
  width: 20px;
  border-radius: 20px;
}

.avatar {
  margin-right: 10px;
}

.alertTitle {
  font-size: 16px;
  font-weight: 600;
  position: relative;
  color: #1b1d1c;
  word-break: break-word;
}

.alertTitle.expanded {
  margin-bottom: 8px;
}

.titleArrow {
  position: relative;
}
.titleArrow.expanded:after {
  display: none;
}

.titleArrow:after {
  content: '';
  position: absolute;
  right: -10px;
  top: 32%;
  width: 5px;
  height: 5px;
  border-right: 2px solid #94a5ba;
  border-bottom: 2px solid #94a5ba;
  transform: rotate(-45deg);
}

.alertDescription {
  font-size: 13px;
  line-height: 1.3;
  margin-top: 8px;
  text-align: left;
  color: #7f8fa4;
  word-break: break-word;
}

.alertItemRow {
  padding-bottom: 0px;
}

.alertContent {
  width: 282px;
  margin-right: var(--gap);
  font-family: 'Muli';
  font-size: 13px;
  text-align: left;
  position: relative;
}

.alertContent:hover {
  cursor: pointer;
}

.userId,
.commentId {
  font-weight: bold;
  color: #354052;
  display: inline-flex;
  align-items: center;
}

.userId::before,
.commentId::before {
  content: '';
  background-size: 16px 16px;
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-right: 2px;
}
.userId::before {
  background-image: url(./img/user_ic.svg);
}
.userId.isOP::before {
  background-image: url(./img/user_op_ic.svg);
}
.commentId::before {
  background-image: url(./img/comment_ic.svg);
}
.time,
.commentId::before {
  margin-left: 8px;
}

.time {
  color: #7697c1;
}

.content {
  display: flex;
  flex-direction: column;
  padding: 16px 0;
}

.empty {
  padding: 0;
}

.noMediaContainer {
  padding: 16px;
  margin: 16px;
}

.contentElement {
  margin: 6px 0;
  padding-left: 24px;
}

.contentHeader {
  display: flex;
  margin-bottom: 5px;
}

.contentBody {
  display: flex;
  width: 100%;
}
.contentUser {
  display: flex;
}
.contentUser,
.contentTime {
  margin-top: 5px;
}

.popupConainer {
  position: relative;
  margin-top: 5px;
}
.contentUser {
  width: calc(var(--col) * 2 + var(--gap));
}

.contentReason {
  width: calc(var(--col) * 3 + var(--gap) * 2);
  margin-right: var(--gap);
}

.contentText {
  width: calc(var(--col) * 6 + var(--gap) * 5);
  margin-right: var(--gap);
  word-break: break-word;
}

.contentTime {
  font-size: 13px;
  font-weight: 500;
  line-height: 1.23;
  text-align: left;
  color: #7f8fa4;
}

.imagesList {
  margin-top: 14px;
}

.imagesListImg {
  margin-right: 12px;
  width: 54px;
  max-height: 54px;
  border-radius: 4px;
}

.label {
  font-size: 11px;
  padding: 5px 10px;
  border-radius: 3px;
  border-style: solid;
  border-width: 1px;
  display: inline-block;
  color: white;
  margin: 2px;
  font-weight: 500;
  text-transform: capitalize;
}

.showHistory {
  position: absolute;
  right: 53px;
  bottom: 20px;
  font-family: 'Muli';
  font-size: 11px;
  font-weight: bold;
  line-height: 1;
  color: #94a5ba;
}
.showHistory:hover {
  cursor: pointer;
}
.showHistory:before {
  content: '';
  transform: rotate(-45deg);
  top: 1px;
  position: absolute;
  right: -10px;
  width: 5px;
  height: 5px;
  border-left: 2px solid #94a5ba;
  border-bottom: 2px solid #94a5ba;
  transition: transform 0.2s;
}
.showHistory.expanded:before {
  top: 4px;
  transform: rotate(-225deg);
}
.new {
  border-color: red;
  color: red;
}

.approve {
  background-color: #47a9f2;
  border-color: #1a91eb;
}

.reject {
  background-color: #fc9630;
  border-color: #f3804e;
}

.escalated {
  background-color: #3d4b99;
}

.flagged {
  background-color: #e7517a;
}
.flagged img {
  vertical-align: -1px;
}

.autoApprove {
  color: #1a91eb;
  border-color: #1a91eb;
}

.autoDecline {
  color: #f3804e;
  border-color: #f3804e;
}

.rsuCommentLink {
  font-family: Muli;
  font-size: 13px;
  font-weight: 600;
  line-height: 1.23;
  text-align: left;
  color: #1998d5;
  cursor: pointer;
  margin-right: 20px;
}

.rsuLabel {
  width: 37px;
  height: 18px;
  border-radius: 3px;
  background-color: #ff7f00;
  border: solid 0.7px #e77300;
  font-family: Muli;
  font-size: 9px;
  font-weight: 900;
  line-height: 1.78;
  text-align: left;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.freeNote {
  margin-left: 0;
}
.freeNote > div {
  background: #f2f4f5;
}
