.leftColumnTitle {
  font-size: 18px;
  font-weight: 300;
  margin: 0 0 15px;
}
.filtersArea {
}
.filterFieldRow {
}
.filterField {
}
.filterField .ant-form-item-label {
}
.filterField label {
}
