.react-checkbox-tree ol {
  list-style: none;
  padding: 0 !important;
}
/* .rct-node-parent {
    display: flex;
    width: 100%;
    flex-direction: column;
} */

.rct-text {
  display: flex;
  justify-content: space-between;
}
.rct-text > label {
  position: relative;
  order: 1;
}
.rct-collapse {
  order: 2;
}
ol,
ul {
  padding-left: 10px;
}

.container {
  position: relative;
}
/* Hide the browser's default checkbox */
.rct-text label input {
  position: absolute;
  opacity: 0;
}

.react-checkbox-tree li {
  margin: 16px 0;
}
/* Create a custom checkbox */
.rct-checkbox {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  border-radius: 3px;
  background-color: #eee;
  cursor: pointer;
}
.rct-collapse {
  border: none;
  position: relative;
  outline: none;
}
button.rct-collapse .rct-icon {
  border: none;
  position: absolute;
  left: 0;
  top: 0;
  border-left: 2px solid #dedede;
  border-bottom: 2px solid #dedede;
  width: 6px;
  height: 6px;
  transform: rotate(225deg);
}
.rct-icon-expand-open {
  transform: rotate(-45deg) !important;
}
.rct-title {
  font-family: 'Muli';
  font-size: 13px;
  font-weight: bold;
  text-align: left;
  color: #354052;
  padding-left: 24px;
  text-transform: capitalize;
}
.rct-icon-half-check {
  position: absolute;
  width: 100%;
  background-color: #2196f3;
  height: 100%;
  border-radius: 4px;
}
.rct-icon-half-check:after {
  transform: rotate(90deg);
  position: absolute;
  content: '';
  z-index: 2;
  border-top: 1px solid #fff;
  width: 2px;
  height: 10px;
  left: 8px;
  top: 3px;
  background-color: #fff;
}
/* On mouse-over, add a grey background color */
.rct-text label:hover input ~ .rct-checkbox {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.rct-text label input:checked ~ .rct-checkbox {
  background-color: #2196f3;
}

.rct-text label input:checked ~ .rct-checkbox-orange {
  background-color: #ff7f00;
}

/* Create the rct-checkbox/indicator (hidden when not checked) */
.rct-checkbox:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the rct-checkbox when checked */
.rct-text label input:checked ~ .rct-checkbox:after {
  display: block;
}

/* Style the rct-checkbox/indicator */
.rct-text label .rct-checkbox:after {
  left: 6px;
  top: 3px;
  width: 6px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.has-children > .rct-text > label .rct-checkbox {
  display: none;
}

.has-children > .rct-text > label .rct-title {
  margin-left: 0;
  padding-left: 0;
  position: relative;
  width: 100%;
  display: inline-block;
  font-family: Muli;
  font-size: 11px;
  text-transform: uppercase;
  line-height: 1.64;
  text-align: left;
  color: #b1c0d4;
}

.has-children > .rct-text > label .rct-title:after {
  content: 'Check Category';
  position: absolute;
  right: 5px;
  top: 0;
  font-family: Muli;
  font-size: 10px;
  font-weight: bold;
  text-align: right;
  color: #1998d5;
  text-transform: none;
}
.rct-collapse-btn {
  background: none;
}
.category-selector {
  margin: 0;
  padding: 0 15px 0 30px !important;
}
.category-selector > ol {
  position: relative;
}
.category-selector > ol:after {
  position: absolute;
  content: '';
  left: -62px;
  width: 330px;
  top: -10px;
  height: 1px;
  border-top: 1px solid #dedede;
}
.category-selector > ol {
  margin-left: 30px;
}
.category-selector > ol > li:nth-of-type(1) {
  margin-top: 30px !important;
}
.has-children {
  margin: 10px 0 !important;
}
.has-children ol {
  margin-left: 20px !important;
}
.has-children > .rct-text > label > input:checked ~ .rct-title:after {
  content: 'Uncheck Category';
}
ol > .no-children:nth-of-type(1) {
  margin-top: 25px !important;
}
.has-children > .rct-text > label {
  width: 100%;
}
.main-category > ol {
  margin-top: 15px;
  border-top: 1px solid #dedede;
}
.main-category > .rct-text {
  padding: 0 15px;
}

.declined input:checked ~ .rct-checkbox,
.declined .rct-icon-half-check {
  background-color: #ff7f00 !important;
}
