.container {
  display: flex;
  max-width: 1280px;
  margin: 0 auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.titleText {
  font-family: Muli;
  font-size: 26px;
  font-weight: bold;
  text-align: left;
  color: #4a4a4a;
}
