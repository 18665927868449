.mainContainer {
  padding-top: 16px;
  padding-bottom: 16px;
  max-width: 1280px;
  margin: 0 auto;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
}
.container {
  float: left;
  padding-right: 30px;
  position: relative;
  display: flex;
}

.item {
  font-family: 'Muli', sans-serif;
  position: relative;
  font-size: 12px;
  font-weight: bold;
  text-align: left;
  color: #7f8fa4;
  position: relative;
}
.arrow {
  transform: rotate(45deg);
}

.desc {
  transform: rotate(225deg);
}

.txt {
  position: relative;
}

.timePerReviewText {
  min-width: 99px;
}

.sortingArrow {
  width: 10px;
  height: 16px;
  background: no-repeat center / 9px url(./img/sorting_arrow_ic.svg);
}

.filterSort {
  display: flex;
  align-items: center;
}

.filterSort:hover {
  cursor: pointer;
}

.checkboxTitle {
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.45;
  letter-spacing: normal;
  text-align: left;
  margin-left: 40px;
  color: #7f8fa4;
}

.padding {
  margin-right: 10px;
}

.txt:hover {
  cursor: pointer;
}

.blk {
  position: relative;
  display: none;
}

.show {
  display: block;
}

.txt:after {
  content: '';
  border: none;
  position: absolute;
  right: -15px;
  top: 4px;
  border-left: 2px solid #7f8fa4;
  border-bottom: 2px solid #7f8fa4;
  width: 4px;
  height: 4px;
  transform: rotate(315deg);
}

.txt:after:hover {
  cursor: pointer;
}

.first {
  width: calc(var(--col) * 3 + var(--gap) * 2);
  margin-right: var(--gap);
}

.second {
  width: 282px;
  margin-right: var(--gap);
}

.third {
  width: calc(var(--col) * 2 + var(--gap));
}

.fourth {
  width: calc(var(--col) * 3 + var(--gap) * 2);
}

.fifth {
  width: calc(var(--col) * 3 + var(--gap) * 2);
  margin-right: var(--gap);
}
