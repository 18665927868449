.container {
  display: flex;
  flex-direction: column;
  font-family: Muli;
  font-size: 11px;
  text-align: left;
  color: #94a5ba;
}

.markersContainer {
  margin-top: 4px;
  display: flex;
  flex-direction: row;
  height: 4px;
}

.timeMarkerContainer {
  flex: 1;
  border-radius: 4px;
  background-color: #dde6ec;
}

.leftContainer {
  margin-right: 2px;
}

.rightContainer {
  margin-left: 2px;
}

.centerContainer {
  margin-left: 2px;
  margin-right: 2px;
}

.blueMarker {
  background-color: #6db0ff;
}

.yellowMarker {
  background-color: #ffd101;
}

.redMarker {
  background-color: #e75153;
}

.redText {
  color: #e75153;
}
