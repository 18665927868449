.container {
  display: flex;
  flex-direction: column;
}

.input {
  display: flex;
  flex: 1;
  border-radius: 2px;
  background-color: #ffffff;
  border: solid 1px #e6eaee;
  padding: 10px;
  font-family: Muli;
  font-size: 13px;
  letter-spacing: -0.2px;
  text-align: left;
  color: #000000;
}
