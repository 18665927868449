:root {
  --paddingSide: 16px;
  --paddingTop: 22px;
}

.container {
  box-sizing: border-box;
  width: 330px;
  padding: var(--paddingTop) 16px;
  flex-direction: column;
  display: flex;
  justify-content: center;
  position: absolute;
  z-index: 1000;
  top: 10px;
  left: -10px;
  border-radius: 2px;
  background-color: #f4f7f9;
  border: solid 1px #dde6ec;
}
.submitButton {
  margin-top: 20px;
  height: 42px;
  border-radius: 4px;
  background-color: #7ed321;
  background-image: none;
  font-family: Muli;
  font-size: 13px;
  font-weight: bold;
  text-align: center;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.submitButton:hover {
  background-color: #7ed321;
  background-image: none;
}
.container :global(.rct-title) {
  text-transform: none;
}

.ringUserContainer {
  border-top: solid 1px #dde6ec;
  margin-top: 15px;
  padding: 10px 0px 0;
}

.rsuTitle {
  display: flex;
  flex-direction: row;
}

.selectAllCheckbox {
  font-size: 13px;
  font-weight: 700;
}

.usersSelectedCounter {
  color: #b1c0d4;
  float: right;
  font-size: 11px;
  font-weight: 600;
}

.selectAllLabel {
  flex-grow: 1;
}

.selectAllLabel:hover input:not(:checked) ~ .selectAllCheckmark {
  background-color: #ccc;
}

.selectAllCheckmark {
  background-color: #eee;
  border: none;
}

.selectAllCheckbox input:checked ~ .selectAllCheckmark {
  background-color: #2594e7;
}

.selectAllTitleClassName {
  cursor: default;
}
