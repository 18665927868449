.container {
  border-radius: 0;
  background-color: #ffffff;
  border: solid 1px #dfe3e9;
  border-left: none;
  padding: 12px 16px 12px 9px;
  transition: width 0.5s;
  width: 130px;
  position: relative;
}

.hint {
  position: absolute;
  top: 110%;
  left: 10px;
  visibility: hidden;
  color: #e75153;
  transform: translateY(-9999px);
  opacity: 0;
  transition: opacity 0.4s, transform 0s;
}

.hint.show {
  transform: translate(0);
  opacity: 1;
  visibility: visible;
}

.focus {
  border-radius: 0;
  border-color: #7f8fa4;
  width: 230px;
}

.input {
  padding: 0;
  background-color: transparent;
  border: 0px solid;
  font-family: Muli;
  font-size: 13px;
  letter-spacing: -0.2px;
  text-align: left;
  color: #354052;
  width: 100%;
  max-width: 100%;
}

.invalid {
  border-color: #e75153;
}

.input::-webkit-input-placeholder {
  color: #c5d0de;
}

.input:focus {
  outline: none;
}

.pressedIcon {
  cursor: pointer;
}

.searchIcon {
  height: 17px;
  width: 17px;
  background: center no-repeat url('./img/search.svg');
}
