@import (reference) './colors.less';
@import (reference) '~antd/dist/antd.less';

* {
  font-weight: 600;
}

.ant-btn {
  font-size: 14px;
}

/* Ant Design theme tweaks */
.ant-input,
.ant-select-lg,
.ant-form label {
  font-size: 13px !important;
  font-family: 'Muli', sans-serif !important;
}

.ant-form-item-label {
  padding-bottom: 1px !important;
}

.ant-form label {
  color: @grey65 !important;
}

/*TODO move to antd customization*/
body .ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled),
body .ant-select-open .ant-select-selection,
body .ant-input,
body .ant-input:focus,
body .ant-input:hover,
body .ant-select-selection,
body .ant-select-selection:focus,
body .ant-select-selection:hover {
  border-width: 2px !important;
}

body .ant-select-selection--multiple .ant-select-selection__choice {
  border-color: transparent;
  font-weight: 500;
}

.ant-btn:not(.ant-btn-primary):not(.ant-btn-link) {
  border: 2px rgba(53, 64, 82, 0.2) solid;
}

.ant-btn {
  font-family: Muli, sans-serif;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
}

.ant-layout-header {
  //height: 60px;
  //border-bottom: 1px rgba(204, 204, 204, .44) solid;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  box-sizing: content-box;
  padding: 0;
  z-index: 1;
}

.ant-layout-content {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: auto;
  -webkit-box-flex: 1;
  -webkit-flex: auto;
  -ms-flex: auto;
  flex: auto;
}

.ant-btn-danger {
  background: #fff;
}

.ant-btn-danger:not([disabled]):not(:hover) {
  background-color: @white100;
  color: @red100;
}

.ant-btn-danger:not([disabled]):hover {
  background-color: #f57f7d;
}

.ant-modal-title {
  width: 496px;
  height: 22px;
  font-family: Muli, sans-serif;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.22;
  letter-spacing: -0.3px;
  color: #354052;
}

.ant-modal-footer {
  background-color: rgba(53, 64, 82, 0.03);
  padding: 13px 24px;
}

/*Label*/
.ant-select-selection--multiple .ant-select-selection__choice {
  background: #eaebed;
}

.ant-form-item-label {
  line-height: 24.9999px;
  padding: 0 0 2px;
}
.ant-form-vertical .ant-form-item {
  margin-bottom: 4px;
}
.ant-form label {
  font-size: 13px;
}

// Upload
// ---
.@{upload-prefix-cls} {
  &&-drag {
    border-style: solid;
    font-weight: 600;
    background-color: @grey3;

    & .ant-upload-text {
      color: @grey65;
    }
    & a {
      color: @blue100;
    }

    &.ant-upload-disabled a {
      cursor: not-allowed;
    }
  }
}
