.overlay {
  background-color: rgba(255, 255, 255, 0.6) !important;
  align-items: flex-start !important;
}

.modal {
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 0 17px 0 rgba(127, 143, 164, 0.5) !important;
  border: solid 1px #ced2e5;
  padding: 0 !important;
  top: 70px !important;
}

.close {
  cursor: pointer;
  fill: #7f8fa4;
  width: 20px !important;
  height: 20px !important;
}
