.commentContainer {
  align-self: flex-start;
  display: flex;
  flex-direction: row;
}

.escalateText {
  display: flex;
  justify-content: center;
}
