.alertItem {
  width: 100%;
  display: flex;
}

.mediaContainer,
.compactMediaContainer {
  display: flex;
  width: 716px;
  height: 403px;
  overflow: hidden;
  margin-right: 4px;
  border-radius: 3px;
}

.compactAlertContainer {
  padding-top: 16px;
  padding-left: 24px;
  padding-bottom: 24px;
}

.boxMediaContainer {
  flex: 0 0 58%;
}

.searchAlertMultimediaViewer {
  display: flex;
  justify-content: center;
  margin-left: 0;
  min-height: 300px;
  border-radius: 3px;
  max-height: 410px;
}

.collapsedMediaContainer {
  width: 60px;
  height: 34px;
  overflow: hidden;
  border-radius: 3px;
}

.editableContainer {
  outline: 2px solid #efba00;
}

.alertItemDecisionContainer {
  flex: 0.8;
}

.editingTimer {
  width: 96px;
}

.alertCloseButton {
  z-index: 4;
  cursor: pointer;
  position: absolute;
  top: -12px;
  right: -12px;
  border-radius: 12px;
  width: 24px;
  height: 24px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.25);
  background-color: #f9fbfc;
  justify-content: center;
  align-items: center;
  display: flex;
}

.alertCloseButtonIcon {
  height: 8px;
  width: 8px;
  -webkit-mask: url(./img/close_alert_ic.svg) no-repeat 100% 100%;
  mask: url(./img/close_alert_ic.svg) no-repeat 100% 100%;
  -webkit-mask-size: cover;
  mask-size: cover;
  background-color: #c5d0de;
}

.alertCloseButton:hover .alertCloseButtonIcon {
  background-color: #7f8fa4;
}

.moderatedTitle {
  flex: 1;
  font-size: 13px;
  font-weight: 900;
  color: #3c485e;
}

.moderatedAlertItemContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  justify-content: flex-start;
  height: 66px;
  border-radius: 3px;
  background-color: #ffffff;
}
