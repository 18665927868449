.labelContainer {
  display: inline-block;
  height: auto;
  width: fit-content;
  padding: 4px 10px 4px 10px;
  margin-right: 4px;
  border: 1px solid transparent;
  border-radius: 3px;
  font-size: 11px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
}

.compacted {
  padding: 2px 8px 2px 8px;
  font-size: 9px;
  font-weight: 900;
}

.inline {
  display: inline-block;
  height: auto;
}

.onRsuLabel {
  border-color: #ff7f00;
  background-color: #ff7f00;
}

.isRsuLabel {
  border-color: #e77300;
  background-color: #ff7f00;
}

.policeLabel {
  border-color: #1699fa;
  background-color: #47a9f2;
}

.approveLabel {
  color: #1479aa;
  border-color: #d4eafb;
  background-color: #d4eafb;
}

.deletedLabel {
  color: #ec7475;
  border-color: #fadce4;
  background-color: #fadce4;
}

.declineLabel {
  color: #e77300;
  border-color: #ffe6cc;
  background-color: #ffe6cc;
}

.autoApprovedLabel,
.newLabel {
  color: #4a90e2;
  border-color: #1a91eb;
  background-color: transparent;
}

.autoDeclinedLabel {
  color: #ff7f00;
  border-color: #f3804e;
  background-color: transparent;
}

.escalated1Label {
  color: #6c4fc5;
  border-color: #e2dcf4;
  background-color: #e2dcf4;
}

.escalated2Label {
  color: #ffffff;
  border-color: #6c4fc5;
  background-color: #6c4fc5;
}

.assignedLabel {
  color: #4c7f14;
  border-color: #7ed321;
  background-color: #7ed321;
}

.flaggedLabel {
  color: #ffffff;
  background: no-repeat center / 18px url('./img/flagged_ic.svg') #f4d7e0;
  border-color: #f4d7e0;
}

.adminLabel {
  color: #ffffff;
  border-color: #7ed321;
  background-color: #7ed321;
}

.curationLabel {
  color: #ffffff;
  border-color: #7ed321;
  background-color: #7ed321;
}

.resolvedLabel {
  color: #ffffff;
  border-color: #7ed321;
  background-color: #7ed321;
}

.crimeLabel {
  color: #ffffff;
  border-color: #354052;
  background-color: #354052;
}
