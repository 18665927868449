.alertItemDescriptionContainer {
  display: flex;
  flex-direction: column;
}

.title {
  font-size: 18px;
  text-align: left;
  color: #354052;
  word-break: break-word;
  max-height: 115px;
  font-weight: 600;
  overflow-x: auto;
}

.link {
  cursor: pointer;
}

.link:hover {
  color: #354052;
}

.titleText .metaInfoContainer,
.titleText .metaInfoCaseContainer {
  flex: 1;
}

.description {
  font-family: Muli;
  font-size: 13px;
  text-align: left;
  color: #7f8fa4;
  word-break: break-word;
  font-weight: 600;
}
