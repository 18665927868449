.alertsContainer {
  padding-top: 25px;
  overflow: scroll;
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: #eff3f6;
}

.alertCommentsModal {
  top: 0 !important;
  margin-top: 0 !important;
}

.alertsItemsContainer {
  flex: 1;
  max-width: 1280px;
}

.container {
  width: 1280px;
  max-width: 1280px;
  margin: 0 auto;
}

.moderateButtonsContainer {
  position: relative;
}

.pagination {
  padding-bottom: 40px;
}

@media (max-width: 1279px) {
  .container {
    width: 720px;
  }
}
