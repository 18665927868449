.commentsThreadContainer {
  background-color: #ffffff;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}
.commentsThreadContainerTop {
  margin-top: 2px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.commentsContainer {
  position: relative;
}

.discusButtonContainer {
  display: flex;
  flex-direction: row;
  padding-left: 24px;
  padding-bottom: 16px;
  font-family: Muli;
  font-size: 11px;
  font-weight: bold;
  line-height: 1.45;
  align-items: center;
}
.discusButtonContainer:first-child {
  padding-top: 16px;
  padding-bottom: 0;
}

.discusButton {
  cursor: pointer;
  color: #1998d5;
}

.discusButton:hover {
  color: #1479aa;
}

.discussButtonIcon {
  margin-left: 6px;
  margin-right: 12px;
  height: 16px;
  width: 16px;
  transform: rotate(-90deg);
  background: no-repeat center / 11px url(./img/dropdown_ic.svg);
}

.openedDropdown {
  transform: rotate(-180deg);
}

.notModeratedBadge {
  background-color: #f5f5f5;
}

.notModeratedBadge sup {
  color: #354052 !important;
  font-size: 11px !important;
}
