#root {
  min-width: 1136px;
  height: 100%;
}

h2,
h3,
h4,
h5 {
  font-weight: normal;
}

.container {
  height: 100%;
  width: 100%;
  position: relative;
  flex-direction: column;
  display: flex;
}

@media (max-width: 1280px) {
  .container {
    height: 100%;
    width: 100%;
    position: relative;
    flex-direction: column;
    display: flex;
  }
}

.menu-item {
  margin: 16px;
  user-select: none;
  font-family: Muli;
  font-size: 13px;
  font-weight: 600;
  line-height: 1.29;
  text-align: left;
  color: #7f8fa4;
}

.linked-menu-item {
  cursor: pointer;
}

.linked-menu-item:hover {
  color: #354052;
}

.linked-menu-item:active {
  color: #7f8fa4;
}

.active-menu-item {
  color: #7f8fa4;
}

.active-menu-item:hover {
  color: #354052;
}

body {
  width: 100vw;
  height: 100vh;
  min-width: 720px;
  font-size: 13px;
  margin: 0;
  padding: 0;
  border: 0;
  font-family: Muli, sans-serif;
}

.scroll::-webkit-scrollbar-track {
  background-color: #ddf0f5;
}

.scroll::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  background-color: #f5f5f5;
}

.scroll::-webkit-scrollbar-thumb {
  background-color: #68bae1;
}

.Select-value {
  background-color: #f4f7f9;
  border: solid 1px #e6eaee;
  color: #7f8fa4;
}

#root {
  display: flex;
  height: 100%;
  /*min-width: 1280px;*/
  width: 100%;
}

.clear {
  clear: both;
}

.row {
  margin-left: 1.2em;
  margin-right: 1.2em;
}

.row-double {
  margin-left: 2.4em;
  margin-right: 2.4em;
}

.row-padding {
  padding-left: 1.2em;
  padding-right: 1.2em;
}

.right-row {
  margin-left: 1.2em;
}

.right-row-double {
  margin-left: 2.4em;
}

.left-row {
  margin-right: 1.2em;
}

.left-row-double {
  margin-right: 2.4em;
}

.column {
  margin-top: 0.8em;
  margin-bottom: 0.8em;
}

.column-double {
  margin-top: 1.6em;
  margin-bottom: 1.6em;
}

.column-padding {
  padding-top: 0.8em;
  padding-bottom: 0.8em;
}

.bottom-column {
  margin-top: 0.8em;
}

.bottom-column-double {
  margin-top: 1.6em;
}

.top-column {
  margin-bottom: 0.8em;
}

.top-column-double {
  margin-bottom: 1.6em;
}

:root {
  --col: 70px;
  --gap: 40px;
}

.rsu-label {
  width: 37px;
  height: 18px;
  border-radius: 3px;
  background-color: #ff7f00;
  border: solid 0.7px #e77300;
  font-family: 'Muli', sans-serif;
  font-size: 9px;
  font-weight: 900;
  line-height: 1.78;
  text-align: left;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}
