@import (reference) '~src/theme/colors.less';

.PermissionUserItem {
  display: flex;
  flex-direction: row;
  align-items: center;

  &__userItem {
    height: 64px;
    background-color: @white100;
    display: flex;
    flex-direction: row;
    width: 840px;

    &.PermissionUserItem__greyStyle {
      background-color: @grey10;
    }
  }

  &__header {
    background-color: @grey10;
    margin: 12px 0;
  }

  &__userEmail {
    padding-left: 32px;
    display: flex;
    align-items: center;
    flex: 1;
    overflow: hidden;
    font-size: 13px;
    font-weight: bold;
    color: @grey100;

    & input {
      flex: 1;
      margin-right: 2px;
    }
  }

  &__controls {
    width: 110px;
    display: flex;
    justify-content: flex-end;
  }

  &__deleteControl {
    width: 52px;
    display: flex;
    justify-content: flex-start;
  }

  &__deleteButton:active {
    background-color: @red100;
  }

  &__checkboxContainer {
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@primary-color: #47a9f2;@info-color: #47a9f2;@success-color: #71bc1f;@processing-color: #47a9f2;@error-color: #e75153;@highlight-color: #e75153;@warning-color: #fc9630;@normal-color: #7b838e;@font-family-no-number: 'Muli', sans-serif;@font-family: 'Muli', sans-serif;@code-family: 'Muli', sans-serif;@border-radius-base: 4px;@heading-color: #354052;@text-color: #354052;@text-color-secondary: #7b838e;@border-color-split: #d7d9dc;@heading-color-dark: #ffffff;@text-color-dark: #354052;@text-color-secondary-dark: fade(#fff, 65%);@font-size-base: 13px;@border-width-base: 2px;@badge-font-weight: 600;@badge-height: 24px;@btn-default-color: #7b838e;@btn-font-weight: 600;@btn-primary-color: #ffffff;@btn-primary-bg: #47a9f2;@btn-primary-border: #47a9f2;@label-color: #7b838e;@form-item-trailing-colon: false;@input-placeholder-color: #b9bcc3;@layout-body-background: #fbfbfc;@layout-header-background: #ffffff;@layout-footer-background: #ffffff;@layout-header-height: 46px;@layout-header-padding: 0 12px;@layout-footer-padding: 0 12px;@link-color: #92cef7;@link-active-color: #92cef7;@link-hover-color: #47a9f2;@link-decoration: none;@modal-mask-bg: rgba(53,64,82,0.65);