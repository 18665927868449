.container {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  z-index: 10;
  padding-left: 23px;
  transition: all 230ms ease-out;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.warning {
  background-color: #ff7f00;
}

.error {
  background-color: #e75153;
}

.info {
  background-color: #77ca1d;
}

.contentContainer {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.messageText {
  flex: 1;
  font-family: Muli;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}

.closeIcon {
  cursor: pointer;
  height: 23px;
  width: 23px;
  background: no-repeat center / 20px url(./img/close_ic.svg);
}
