.commentContainer {
  padding: 9px 24px 9px 24px;
  font-family: Muli;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  display: flex;
  flex-direction: row;
}

.commentContent {
  width: 640px;
  word-break: break-word;
  margin-right: 100px;
}

.bottomCommentContainer {
  padding-bottom: 18px;
}

.topCommentContainer {
  padding-top: 18px;
}

.commentHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 6px;
}

.userIdField,
.timeAgoField,
.commentIdField {
  font-size: 13px;
  line-height: normal;
}

.commentIdField,
.userIdField {
  font-weight: bold;
  color: #354052;
  display: inline-flex;
  align-items: center;
}
.commentIdField::before,
.userIdField::before {
  content: '';
  background-size: 16px 16px;
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-right: 2px;
}
.userIdField::before {
  background-image: url(./img/user_ic.svg);
}
.userIdField.isOP::before {
  background-image: url(./img/user_op_ic.svg);
}
.commentIdField::before {
  background-image: url(./img/comment_ic.svg);
}
.timeAgoField,
.commentIdField::before {
  margin-left: 8px;
}

.timeAgoField {
  font-weight: 600;
  color: #ced2e5;
}

.rsuLabel {
  margin-right: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 37px;
  height: 18px;
  border-radius: 3px;
  background-color: #ff7f00;
  border: solid 0.7px #e77300;
  font-size: 9px;
  font-weight: 900;
  line-height: 1.78;
  color: #ffffff;
}

.flaggedIcon {
  margin-left: 2px;
  width: 16px;
  height: 16px;
  background: no-repeat center / 16px url('./img/flagged_ic.svg');
}

.rsuReadButton {
  width: 124px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: #c5d0de;
}

.timerContainer {
  max-height: 56px;
  display: flex;
  align-items: center;
}

.timer {
  width: 96px;
}

.timerContainer:empty {
  display: none;
}

.readIcon {
  height: 10px;
  width: 12px;
  -webkit-mask: url(./img/read_ic.svg) no-repeat 100% 100%;
  mask: url(./img/read_ic.svg) no-repeat 100% 100%;
  -webkit-mask-size: cover;
  mask-size: cover;
  background-color: #c5d0de;
}

.rsuReadButton:hover {
  color: #7f8fa4;
}

.rsuReadButton:hover .readIcon {
  background-color: #7f8fa4;
}

.moderationToolsContainer {
  margin-left: 40px;
  margin-right: 10px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.moderationToolsContainerHeight {
  max-height: 56px;
}

.labelsContainer {
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.commentModerationContainer {
  flex: 1;
  max-height: 56px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

@media (max-width: 1279px) {
  .commentContent {
    min-width: 348px;
    max-width: 348px;
  }

  .labelsContainer {
    margin-left: 10px;
  }
}

.toggleButton {
  margin-left: 16px;
}

.toggleUserViolationsButton {
  margin-left: 10px;
  margin-right: 10px;
}

.userViolationsContainer {
  width: 100%;
  margin: 0 0 10px 100px;
  padding: 5px;
  border: 1px solid #ddd;
}

.userViolationsHeader {
  margin-left: 4px;
  color: #e75153;
}
