.container {
  border-radius: 3px 0 0 3px;
  background-color: #ffffff;
  border: solid 1px #dde6ec;
  font-family: Muli;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: -0.2px;
  text-align: left;
  color: #c5d0de;
  padding: 12px 16px;
  display: flex;
  position: relative;
  font-weight: 300;
  transition: color 0.2s;
  justify-content: space-between;
  min-width: 140px;
}

.container:hover {
  border: solid 1px #dde6ec;
  cursor: pointer;
  color: #7f8fa4;
}

.container.focus:hover,
.focus {
  border-color: #7f8fa4;
  color: #7f8fa4;
}
.container.invalid {
  border-right-color: #e75153;
}
.dropdownImage {
  margin-left: 15px;
}

.container.open {
  border-radius: 3px;
  background-color: rgba(230, 234, 238, 0.2);
  border: solid 1px #dde6ec;
}

.dropdown {
  box-sizing: border-box;
  position: absolute;
  z-index: 2;
  left: -1px;
  display: none;
  width: calc(100% + 2px);
  top: calc(100% + 2px);
  border-radius: 3px;
  background-color: #ffffff;
  border: solid 1px #dde6ec;
}

.dropdown.open {
  display: block;
}

.dropdownElement {
  padding: 12px 16px;
  border: solid 1px transparent;
  background-color: rgba(239, 243, 246, 0.6);
  background-color: #fff;
}
.dropdownElement:hover {
  border-top: solid 1px #dde6ec;
  border-bottom: solid 1px #dde6ec;
  background-color: rgba(239, 243, 246, 0.6);
}

.dropdownElement:first-of-type {
  border-radius: 2px 2px 0 0;
}

.dropdownElement:last-of-type {
  border-radius: 0 0 2px 2px;
}

.dropdownElement:first-of-type:hover {
  border-top: solid 1px transparent;
}

.dropdownElement:last-of-type:hover {
  border-bottom: solid 1px transparent;
}

:global #arrow {
  transition: fill 0.2s;
  fill: #dde6ec;
}

.container:hover :global #arrow,
.focus :global #arrow {
  fill: #7f8fa4;
}
