.container {
  display: flex;
}

.button {
  border-radius: 0 3px 3px 0;
  background-color: #47a9f2;
  color: #fff;
  padding: 12px 16px 12px 9px;
  cursor: pointer;
}
.button.disable {
  cursor: not-allowed;
}
