.main__container {
  background: #f9f9f9;
  display: block;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: auto;
  -webkit-box-flex: 1;
  -webkit-flex: auto;
  -ms-flex: auto;
  flex: auto;
}

.contentContainer {
  flex: 1;
  position: relative;
  min-height: 200px;
}

:root body {
  height: 100%;
  overflow: hidden;
}

:root #root {
  display: flex;
  width: 100%;
}
