.searchInput {
  position: relative;
}
.searchInput input {
  padding-right: 28px;
}
.resetIcon {
  top: 50%;
  right: 0;
  margin-top: -7px;
  position: absolute;
  cursor: pointer;
  opacity: 0.7;
}
.resetIcon:hover {
  opacity: 1;
}
