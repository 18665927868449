.container {
  display: flex;
  flex-direction: row;
}

.undoButton {
  min-width: 80px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 36px;
  font-weight: bold;
  border-radius: 4px;
  color: #7f8fa4;
  background-image: linear-gradient(to bottom, #e0e8ee, #e0e8ee 98%);
  border: solid 1px #e0e8ee;
}

.undoButton:hover {
  background-image: linear-gradient(to bottom, #ced7e4, #ced7e4 98%);
  border: solid 1px #ced7e4;
}

.csvButton {
  min-width: 175px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 36px;
  border-radius: 4px;
  font-family: Muli;
  font-size: 13px;
  font-weight: bold;
  text-align: left;
  color: #ffffff;
  background-image: linear-gradient(to bottom, #47a9f2, #47a9f2 98%);
  border: solid 1px #47a9f2;
}

.csvButton:hover {
  background-image: linear-gradient(to bottom, #2594e7, #2594e7 98%);
  border: solid 1px #2594e7;
}

.icon {
  width: 16px;
  height: 16px;
  background: no-repeat center / 16px url('./img/generate_ic.svg');
}

.activeIcon {
  width: 16px;
  height: 16px;
  background: no-repeat center / 16px url('./img/download_ic.svg');
}

.csvActiveButton {
  background-image: linear-gradient(to bottom, #7ed321, #7ed321 98%);
  border: solid 1px #7ed321;
}

.csvActiveButton:hover {
  background-image: linear-gradient(to bottom, #77ca1d, #77ca1d 98%);
  border: solid 1px #77ca1d;
}
