.approveButton,
.rejectButton,
.devider {
  font-size: 15px;
  font-weight: 600;
  cursor: pointer;
  color: #c5d0de;
}

.approveButton {
  color: #47a9f2;
}

.approveButton:hover {
  color: #2594e7;
}

.rejectButton {
  color: #fc9630;
}

.rejectButton:hover {
  color: #ff7f00;
}

.devider {
  cursor: default;
  margin: 0 10px 0 10px;
}

.buttonsDisabled {
  opacity: 0.8;
  pointer-events: none;
}
