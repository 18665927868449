.devideLine {
  height: 1px;
  background-color: #dde6ec;
}

.link {
  user-select: none;
  text-decoration: none;
  font-family: Muli;
  font-size: 14px;
  font-weight: bold;
  text-align: left;
  color: #ffffff;
  cursor: pointer;
  position: relative;
}
